import React from 'react'

function DarkbackgroundComp() {
    return (
        <div className="darkBackground">
            
        </div>
    )
}

export default DarkbackgroundComp
