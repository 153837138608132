import React, {useState, useEffect} from 'react'
import { Fade, Zoom } from "react-awesome-reveal";
import ButtonComp from './ButtonComp';
import axios from 'axios';

function Environnementpage({contactActionButton, lang}) {
    
    const Images = [
        "../imgs/env1.png" ,
        "../imgs/env2.png" ,
        "../imgs/env3.png" ,
        "https://groupelitho.com/uploads/Recyc_FR_LS_White_GRAPHISCAN_19b574813f.png" ,
        "https://groupelitho.com/uploads/Recyc_FR_LS_White_GRAPHISCAN_19b574813f.png"
      ];

        // FETCH DATA
        const [pageData, setPageData] = useState({});

        let langPos;
        lang === "FR"
        ? (langPos = "1")
        : (langPos = "2");

        useEffect(() => {
            getPageData();
            // eslint-disable-next-line
        }, [langPos]);

        const getPageData = async () => {
            const response = await axios.get("https://groupelitho.com/enviros/" + langPos);
                setPageData(response.data); 
        };
        // END FETCH DATA
    
    return (
        <div className="environnementPage">
            <div className="evnContainer">
                <Fade delay={300}>
                    <div className="sectionTitle">
                        {pageData.title}
                    </div>

                    <p>{pageData.intro}</p>

                    <div className="certif">
                        <span className="title"> 
                        {/*pageData.title1*/}
                        <div dangerouslySetInnerHTML={{__html: pageData.title1}} />
                        </span>
                        <span className="content">
                        {pageData.text1}
                        </span>
                    </div>

                    <div className="certif">
                        <article>
                            {/*<img src={Images[3]} alt=""/>

                            <img src={Images[4]} alt=""/>*/}

                            <b>
                                Licence:<br/> FSC<sup>®</sup> C100178
                            </b>
                        </article>
                    </div>

                    <div className="saviezvous">
                        <span className="title"> 
                        {pageData.catch}
                        </span>

                        <div className="facts">

                            <article className="fact">
                                <section className="img">
                                    <img className="imgfact" src={Images[0]} alt=""/>
                                </section>
                                <section className="text">
                                {pageData.bloc1}
                                </section>
                            </article>

                            <article className="fact center">
                                <section className="img">
                                    <img className="imgfact" src={Images[1]} alt=""/>
                                </section>
                                <section className="text">
                                {pageData.bloc2}
                                </section>
                            </article>

                            <article className="fact">
                                <section className="img">
                                    <img className="imgfact" src={Images[2]} alt=""/>
                                </section>
                                <section className="text">
                                {pageData.bloc3}
                                </section>
                            </article>

                        </div>

                    </div>
    
                </Fade>

                <div className="callToAction">    
                    <Zoom delay={500}>
                        <ButtonComp buttonAction={contactActionButton} buttonText={pageData.button} />
                    </Zoom>
                </div>

            </div>
        </div>
    )
}

export default Environnementpage
