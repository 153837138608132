import React, {useState, useEffect} from 'react'
import CountComp from './CountComp';
import { Fade, Zoom } from "react-awesome-reveal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import axios from 'axios';

function Servicespage({ lang }) {


    // FETCH DATA
    const [pageData, setPageData] = useState({});

    let langPos;
    lang === "FR"
    ? (langPos = "1")
    : (langPos = "2");

    useEffect(() => {
        getPageData();
        // eslint-disable-next-line
    }, [langPos]);

    const getPageData = async () => {
        const response = await axios.get("https://groupelitho.com/services/" + langPos);
            setPageData(response.data); 
    };
    // END FETCH DATA

    const Blocks = [
        {
            title: pageData.boxtitle1,
            image: pageData.boximg1,
            link: "/impression",
        },
        {
            title: pageData.boxtitle2,
            image: pageData.boximg2,
            link: "/emballage",
        },
        {
            title: pageData.boxtitle3,
            image: pageData.boximg3,
            link: "/publipostage",
        },
    ];

    function getSubService(arr) {
        //console.log(arr);
    }
    
    return (
        <div className="servicesPage">
            <Fade delay={300}>
            <div className="sectionTitle">
            {pageData.title}
            </div>

            <div className="sectionTextes">
                <p>
                {pageData.text}
                </p>
            </div>
            </Fade>

            <div className="sectionNumbers">
            <Fade delay={500}>
                    <CountComp 
                        boxnum1={pageData.num1} 
                        boxnum2={pageData.num2} 
                        boxnum3={pageData.num3} 
                        boxtitle1={pageData.title1} 
                        boxtitle2={pageData.title2} 
                        boxtitle3={pageData.title3}
                    />
            </Fade>
            </div>

            <Zoom delay={700}>
                <div className="sectionBlocks">
                {
                    Blocks.map(function(block, i) {
                        return (
                            <Link to={block.link}>
                            <div className="serviceBlock" onClick={getSubService(block.services)}>
                                <div className="serviceBlockBack" style={{'backgroundImage': `url("https://groupelitho.com${block.image}")`,
                                                                            'backgroundSize': `cover`,
                                                                            'backgroundPosition': `center center` 
                                                                        }}>
                                    <div className="serviceBlocktext">
                                        {block.title} <FontAwesomeIcon icon={faPlusCircle} />
                                    </div>
                                </div>
                            </div>
                            </Link>
                        );
                    })
                }

                </div>
            </Zoom>
        </div>
    )
}

export default Servicespage
