import React from 'react'
//import BackgroundVideo from './imgs/video.webm';

const VideoBackground = () => {


    /*const videoOptions = {
        src: 'http://download.blender.org/peach/bigbuckbunny_movies/BigBuckBunny_320x180.mp4',
        ref: videoRef => {
          this.videoRef = videoRef;
        },
        onClick: () => {
          if (this.videoRef && this.videoRef.paused) {
            this.videoRef.play();
          } else if (this.videoRef) {
            this.videoRef.pause();
          }
        },
        title: 'click to play/pause',
      };*/

      function iOS() {
        return [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      }

      let isios = false;
      if (iOS() === true) {
        isios = true;
      }
      else {
        isios = false;
      }

      const vid = [
        "https://groupelitho.com/uploads/video_e6ac847c61.webm" ,
        "https://groupelitho.com//uploads/video_e6ac847c61_07f5ebc822.mp4" ,
      ];

    return (
        <div style={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
          }}
          id="videoContainer">
            <video 
            id="video"
            style={{
                objectFit: 'cover',
                width: '100%',
                height: '100%',
              }} autoPlay loop muted playsInline controls={{isios}}>
                
                <source src={vid[1]} type="video/mp4"/>
                <source src={vid[0]} type="video/webm"/>
                Your browser does not support the video tag.
            </video>
        </div>
    )
}

export default VideoBackground
