import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import axios from 'axios';


function Rea1() {

    
        window.scrollTo(0,0);

                // FETCH DATA
                const [pageData, setPageData] = useState({});
        
                useEffect(() => {
                    getPageData();
                    // eslint-disable-next-line
                }, []);
        
                const getPageData = async () => {
                    const response = await axios.get("https://groupelitho.com/realisations/1");
                        setPageData(response.data); 
                };
                // END FETCH DATA

    return (
        <div className="reapage">
            <Link style={{ color: 'inherit', textDecoration: 'inherit'}} to="/">
            <div className="iconClose">
            <FontAwesomeIcon 
                icon={faTimes}
                
                />
            </div>
            </Link>

            <div className="reaslide">
                <article className="left">
                    <section>
                    <FontAwesomeIcon 
                    icon={faAngleLeft}/>
                    </section>
                </article>

                <article className="middle" style={{'backgroundImage': `url("https://groupelitho.com${pageData.ImgLarge}")`, 'backgroundSize': `cover`, 'backgroundPosition': `center bottom` }}>

                </article>

                <article className="right">
                    <Link style={{ color: 'inherit', textDecoration: 'inherit'}} to="/rea2">
                    <section>
                    <FontAwesomeIcon 
                    icon={faAngleRight}/>
                    </section>
                    </Link>
                </article>
            </div>


            <div className="content">
                    <article class="title">
                    {pageData.title}
                    </article>

                    <article className="text">
                    {pageData.content}
                    </article>
            </div>

        </div>
    )
}

export default Rea1
