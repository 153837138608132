import React, {useState, useEffect} from 'react'
import { Fade } from "react-awesome-reveal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import Logo from './imgs/gd.png';
import Collapsible from 'react-collapsible';
import ButtonComp from './ButtonComp';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';


function Impressionpage( {soumissionActionButton} ) {


    const storedLang = localStorage.getItem("lang");
    // eslint-disable-next-line
    const [lang, setLang] = useState(
      storedLang ? storedLang : "FR"
      );

        // FETCH DATA
        const [pageData, setPageData] = useState([]);
        const [pageData1, setPageData1] = useState([]);
        const [pageData2, setPageData2] = useState([]);

        useEffect(() => {
            getPageData();
            getPageData1();
            getPageData2();
            window.scrollTo(0,0);
            // eslint-disable-next-line
        }, []);

        const getPageData = async () => {
            const response = await axios.get("https://groupelitho.com/serviceitems/");
                setPageData(response.data); 
        };

        const getPageData1 = async () => {
            const response1 = await axios.get("https://groupelitho.com/servicesrub-2-s/");
                setPageData1(response1.data); 
        };

        const getPageData2 = async () => {
            const response2 = await axios.get("https://groupelitho.com/servicesrub-3-s/");
                setPageData2(response2.data); 
        };
        // END FETCH DATA

    return (
<div className="servicepop">



<Link style={{ color: 'inherit', textDecoration: 'inherit'}} to="/">
<div className="iconClose">
<FontAwesomeIcon 
    icon={faTimes}
    
    />
</div>
</Link>

<Fade top>
<Link style={{ color: 'inherit', textDecoration: 'inherit'}} to="/">
    <section className="logo poplogo">
        <img src={Logo} alt="" />
    </section>
</Link>
</Fade>

<Fade top>
    
<div className="sectionTitle">
{lang === "FR" ? "IMPRESSION" : "PRINTING"}
</div>

</Fade>

<br/><br/>
<div className="servicepoptext">

{
    pageData.map(function(datapoint, i) {
        return (
            <div>
            { lang === datapoint.lang &&
                (<Collapsible trigger={[datapoint.title , <FontAwesomeIcon className="collapseServices" icon={faPlusCircle} />]}> 
                
                <div dangerouslySetInnerHTML={{__html: datapoint.text}} />

                <Link to={datapoint.url}>
                <ButtonComp buttonAction={soumissionActionButton} buttonText={datapoint.button} />
                </Link>
            </Collapsible>
                ) 
            }
            </div>
        );
    })
}



</div>





<Fade top>
    
<div className="sectionTitle">
{lang === "FR" ? "AUTRES SERVICES" : "OTHER SERVICES"}
</div>

</Fade>

<br/><br/>
<div className="servicepoptext">

{
    pageData1.map(function(datapoint, i) {
        return (
            <div>
            { lang === datapoint.lang &&
                (<Collapsible trigger={[datapoint.title , <FontAwesomeIcon className="collapseServices" icon={faPlusCircle} />]}> 
                
                <div dangerouslySetInnerHTML={{__html: datapoint.text}} />

                <Link to={datapoint.url}>
                <ButtonComp buttonAction={soumissionActionButton} buttonText={datapoint.button} />
                </Link>
            </Collapsible>
                ) 
            }
            </div>
        );
    })
}
{
    pageData2.map(function(datapoint, i) {
        return (
            <div>
            { lang === datapoint.lang &&
                (<Collapsible trigger={[datapoint.title , <FontAwesomeIcon className="collapseServices" icon={faPlusCircle} />]}> 
                
                <div dangerouslySetInnerHTML={{__html: datapoint.text}} />

                <Link to={datapoint.url}>
                <ButtonComp buttonAction={soumissionActionButton} buttonText={datapoint.button} />
                </Link>
            </Collapsible>
                ) 
            }
            </div>
        );
    })
}



</div>




</div>
    )
}

export default Impressionpage
