import React, {useState, useEffect} from 'react'
import axios from 'axios';

function FooterComp({ lang }) {

        // FETCH DATA
        const [pageData, setPageData] = useState({});

        let langPos;
        lang === "FR"
        ? (langPos = "1")
        : (langPos = "2");

        useEffect(() => {
            getPageData();
            // eslint-disable-next-line
        }, [langPos]);

        const getPageData = async () => {
            const response = await axios.get("https://groupelitho.com/titles/" + langPos);
                setPageData(response.data); 
        };
        // END FETCH DATA

    return (
        <div className="footer">
            {pageData.copyright}
        </div>
    )
}

export default FooterComp
