import React, {useState, useEffect} from 'react'
import { Fade, Zoom } from "react-awesome-reveal";
import ButtonComp from './ButtonComp';
import { Link } from 'react-router-dom';
import axios from 'axios';

function Realisationspage({soumissionActionButton, lang}) {

        // FETCH DATA
        const [pageData, setPageData] = useState({});
        const [pageDataitems, setPageDataitems] = useState([]);

        let langPos;
        lang === "FR"
        ? (langPos = "1")
        : (langPos = "2");

        useEffect(() => {
            getPageData();
            getPageDataitems();
            // eslint-disable-next-line
        }, [langPos]);

        const getPageData = async () => {
            const response = await axios.get("https://groupelitho.com/titles/" + langPos);
                setPageData(response.data); 
        };

        const getPageDataitems = async () => {
            const response = await axios.get("https://groupelitho.com/realisations/");
                setPageDataitems(response.data); 
        };

        // END FETCH DATA

    return (
        <div className="realisationsPage">
            <Fade delay={300}>
                <div className="sectionTitle">
                    {pageData.realisations}
                </div>
            </Fade>

            <Fade delay={700}>
                <div className="theGrid">
                    {   
                        pageDataitems.map(function(Realisation, i) {
                            return (
                                <Link to={Realisation.link}>
                                <article className="realisation" style={{'backgroundImage': `url("https://groupelitho.com${Realisation.image}")`,
                                'backgroundSize': `cover`,
                                'backgroundPosition': `center center` 
                                }}>
                            
                                </article>
                                </Link>
                            );
                        })
                    }
                </div>
            </Fade>

            <div className="callToAction">    
                <Zoom delay={500}>
                    <Link to="/soumission">
                        <ButtonComp buttonAction={soumissionActionButton} buttonText={pageData.soumission} />
                    </Link>
                </Zoom>
            </div>
        </div>
    )
}

export default Realisationspage
