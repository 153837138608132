import './App.css';
import Main from './components/Main';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Soumissionpage from './components/Soumissionpage';
import Soumissionpagegformat from './components/Soumissionpagegformat';
import Soumissionpageinfomtl from './components/Soumissionpageinfomtl';
import Soumissionpagenum from './components/Soumissionpagenum';
import Publipostagepage from './components/Publipostagepage';
import Emballagepage from './components/Emballagepage';
import Impressionpage from './components/Impressionpage';
import Rea1 from './components/Rea1';
import Rea2 from './components/Rea2';
import Rea3 from './components/Rea3';
import Rea4 from './components/Rea4';
import Rea5 from './components/Rea5';
import Rea6 from './components/Rea6';
import Rea7 from './components/Rea7';
import Rea8 from './components/Rea8';
import Rea9 from './components/Rea9';
import Rea10 from './components/Rea10';
import Rea11 from './components/Rea11';
import Rea12 from './components/Rea12';
import Done from './components/Done';

function App() {
  
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/" exact component={Main} />
          <Route path="/soumission" exact component={Soumissionpage} />
          <Route path="/soumissioninfomtl" exact component={Soumissionpageinfomtl} />
          <Route path="/soumissionnum" exact component={Soumissionpagenum} />
          <Route path="/soumissiongformat" exact component={Soumissionpagegformat} />
          <Route path="/publipostage" exact component={Publipostagepage} />
          <Route path="/emballage" exact component={Emballagepage} />
          <Route path="/impression" exact component={Impressionpage} />
          <Route path="/Rea1" exact component={Rea1} />
          <Route path="/Rea2" exact component={Rea2} />
          <Route path="/Rea3" exact component={Rea3} />
          <Route path="/Rea4" exact component={Rea4} />
          <Route path="/Rea5" exact component={Rea5} />
          <Route path="/Rea6" exact component={Rea6} />
          <Route path="/Rea7" exact component={Rea7} />
          <Route path="/Rea8" exact component={Rea8} />
          <Route path="/Rea9" exact component={Rea9} />
          <Route path="/Rea10" exact component={Rea10} />
          <Route path="/Rea11" exact component={Rea11} />
          <Route path="/Rea12" exact component={Rea12} />
          <Route path="/Done" exact component={Done} />
        </Switch>
        
      </div>
    </Router>
  );
}

export default App;
