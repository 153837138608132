import React, {useState, useEffect} from 'react'
import { Fade, Slide } from "react-awesome-reveal";
import axios from 'axios';
import emailjs from "emailjs-com";

function ChatComp({ openchat, openChatStatus, lang }) {

    
    
    const Images = [
        "https://groupelitho.com/uploads/chatbubble_cb16d0ed9b.png" ,
      ];

        // FETCH DATA
        const [pageData, setPageData] = useState({});

        let langPos;
        lang === "FR"
        ? (langPos = "1")
        : (langPos = "2");

        useEffect(() => {
            getPageData();
            // eslint-disable-next-line
        }, [langPos]);

        const getPageData = async () => {
            const response = await axios.get("https://groupelitho.com/chats/" + langPos);
                setPageData(response.data); 
        };
        // END FETCH DATA


        function sendEmail(e) {
            e.preventDefault();
        
            emailjs.sendForm('service_lkvci2i', 'template_u1e97mb', e.target, 'user_d6P340Gc5K9lCAmL5BxGQ')
              .then((result) => {
                  console.log(result.text);
              }, (error) => {
                  console.log(error.text);
              });
              e.target.reset();
              window.location.replace("https://graphiscan.ca/");
          }


    return (
        <>
            <div className="chatbubble">



            <div style={{display: openChatStatus ? 'block' : 'none'}}>
                    <Fade delay={0} duration={100} top>
                        <article className="chatsection">
                            <article className="top">
                                <section className="close" onClick={openchat}>X</section>
                                {pageData.title}
                            </article>
                            <article className="msgs">
                                {/*<section className="msg">Bonjour !</section>*/}
                                <form onSubmit={sendEmail}>
                                    <input type="text" name="nom" placeholder={pageData.nameInput} required/>
                                    <input type="text" name="email" placeholder={pageData.emailInput} required/>
                                    <input type="text" name="tel" placeholder={pageData.phoneInput} required/>
                                    <textarea name="message" placeholder={pageData.messageInput} required></textarea>

                                    <button className="contactSend">{pageData.buttonInput}</button>
                                </form>
                            </article>
                        </article>            
                    </Fade>
                </div>



                <div>
                    <Slide delay={2000} direction="right">
                        <article className="chaticon" onClick={openchat} style={{display: openChatStatus ? 'none' : 'block'}} >
                            {/*openChatStatus ? '...' : '...'*/}  
                            <img src={Images[0]} alt=""/>
                        </article>            
                    </Slide>
                </div>
                
            </div>
        </>
    )
}

export default ChatComp
