import React, {useState, useEffect} from 'react'
import { Fade, Zoom } from "react-awesome-reveal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import Logo from './imgs/gd.png';
import axios from 'axios';
import emailjs from "emailjs-com";


function Soumissionpage() {

    const storedLang = localStorage.getItem("lang");
    // eslint-disable-next-line
    const [lang, setLang] = useState(
      storedLang ? storedLang : "FR"
      );

  // FETCH DATA
  const [pageData, setPageData] = useState({});

  let langPos;
  lang === "FR"
  ? (langPos = "1")
  : (langPos = "2");

  useEffect(() => {
      getPageData();
      window.scrollTo(0,0);
      // eslint-disable-next-line
  }, [langPos]);

  const getPageData = async () => {
      const response = await axios.get("https://groupelitho.com/soumissions/" + langPos);
        setPageData(response.data); 
  };
  // END FETCH DATA

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_lkvci2i', 'template_3ggbbj7', e.target, 'user_d6P340Gc5K9lCAmL5BxGQ')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  }


    return (
        <div className="soumissionform">



            <Link style={{ color: 'inherit', textDecoration: 'inherit'}} to="/">
            <div className="iconClose">
            <FontAwesomeIcon 
                icon={faTimes}
                
                />
            </div>
            </Link>

            <Fade top>
            <Link style={{ color: 'inherit', textDecoration: 'inherit'}} to="/">
                <section className="logo poplogo">
                    <img src={Logo} alt="" />
                </section>
            </Link>
            </Fade>

            <Fade top>
                
            <div className="sectionTitle">
                {pageData.title}
            </div>
           
            </Fade>

            <br/><br/>

            <Zoom delay={200} duration={500}>
            <div className="contactform">
                <form onSubmit={sendEmail}>
                    <input type="text" name="nom" placeholder={pageData.nameInput} required/>
                    <input type="text" name="email" placeholder={pageData.emailInput} required/>
                    <input type="text" name="tel" placeholder={pageData.phoneInput} required/>
                    <input type="text" name="entreprise" placeholder={pageData.companyInput} />
                    <input type="text" name="ref" placeholder={pageData.refInput}/>
                    <textarea name="message" placeholder={pageData.contentInput} required></textarea>
                    {/*<br/><br/>
                    <article style={{'fontWeight':'bold'}}>Bot verification :<br/></article>
                    <input type="number" name="ref" placeholder="1 + 1 = ?" className="verif"/>*/}

                    <button className="contactSend">{pageData.submitText}</button>
                </form>
                <br/>
            </div>
            </Zoom>
        </div>
    )
}

export default Soumissionpage
