import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

function HeaderComp({logo, slideMenu, iconMenu, changeLang, currentLang}) {

    const Images = [
        "https://groupelitho.com/uploads/Linked_In_86547f5d47.png" ,
        "../imgs/en.png" ,
        "../imgs/fr.png" ,
      ];


    return (
        <header>
            <div className="header" >
                <nav className="menu">
                    <span className="theIcon">
                        <FontAwesomeIcon 
                            icon={ iconMenu ? faTimes : faBars }
                            onClick={slideMenu}
                        />
                    </span>
                </nav>          


                <section className="logo">
                    <img src={logo} alt="" onClick={() => {window.scrollTo({ top: 0,  behavior: 'smooth', })}} />
                </section>

                <section className="cartIcon">
                    <span className="theIcon">
            

                        <span className="iconCont">
                            <img onClick={changeLang} className="img1" src={currentLang === "FR" ? Images[1] : Images[2]} alt="LANG"/>
                        </span>

                        

                        <span className="iconCont">
                            <a style={{ color: 'inherit', textDecoration: 'inherit'}} 
                                 href="https://www.linkedin.com/company/graphiscan-montr%C3%A9al-inc/">
                            <img className="img2" src={Images[0]} alt="LI" title="LinkedIn"/>
                            </a>
                        </span>
                        &nbsp;&nbsp;
    
                    </span>
                </section>
            </div>
        </header>
    )
}

export default HeaderComp
