import React, {useState, useEffect} from 'react'
//import { Link } from 'react-router-dom';
import { Slide } from "react-awesome-reveal";
import axios from 'axios';


function SoumissionComp({lang}) {

  // FETCH DATA
  const [pageData, setPageData] = useState({});

  let langPos;
  lang === "FR"
  ? (langPos = "1")
  : (langPos = "2");

  useEffect(() => {
      getPageData();
      // eslint-disable-next-line
  }, [langPos]);

  const getPageData = async () => {
      const response = await axios.get("https://groupelitho.com/soumissions/" + langPos);
        setPageData(response.data); 
  };
  // END FETCH DATA

    return (
        <>
        <div className="soumission">
            <Slide delay={2500} direction="up">
            {/*<img src="http://dzpro.net/apps/docs/soum.png" alt=""/>*/}

                <article className="soumissionButton">{pageData.button}</article>
                
            {/*<article className="submissionTitle"></article>
            <article className="submissionContent"></article>*/}
            </Slide>
        </div>
        </>
    )
}

export default SoumissionComp
