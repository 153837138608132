import React from 'react'
import CountUp from 'react-countup';

export const CountComp = ({boxnum1, boxnum2, boxnum3, boxtitle1, boxtitle2, boxtitle3}) => {

    return (
        <div className="countup">
            <section className="counter red"><CountUp end={boxnum1} duration={5} delay={2} onEnd={({ pauseResume, reset, start, update }) => start()}/></section>
            <section className="counter blue"><CountUp end={boxnum2} duration={5} delay={2} onEnd={({ pauseResume, reset, start, update }) => start()}/></section>
            <section className="counter yellow"><CountUp end={boxnum3} duration={5} delay={2} onEnd={({ pauseResume, reset, start, update }) => start()}/></section>
            
            <section className="description desred">{boxtitle1}</section>
            <section className="description desblue">{boxtitle2}</section>
            <section className="description desyellow">{boxtitle3}</section>
            
        </div>
    )
}

export default CountComp