import React from 'react'

//setTimeout(function(){
//    window.location.replace("https://graphiscan.ca/");
//  }, 5000);




function Done() {
    return (
        <div className="done">
            <div className="done_text">Success !!!</div> 
        </div>
    )
}

export default Done
